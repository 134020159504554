.blink::after{
    content: "_";
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    from,to{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
}