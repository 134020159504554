@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollable-container{
    overflow-y: auto;
    max-height: 80vh;
    padding: 0 2rem;
    width: 100%;
}

.scrollable-container::-webkit-scrollbar {
    width: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.btn {
    appearance: none;
    margin: 1px 3px;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.btn:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}


@layer base{
    .btn{
        @apply cursor-pointer bg-midBlue text-white font-bold text-base sm:text-xl rounded-full px-6 pt-1 pb-2 my-6 sm:my-6 hover:bg-midGreen hover:text-dark transition-all duration-300 ease-in-out;
    }
}

input{
    color:black;
}

b{
    font-weight: 600;
}