

/*.example-container {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: rgba(240, 240, 240, 0.4);
  border-radius: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.img {
  position: absolute;
  max-width: 100%;
  /*max-height: 100%;*/
  object-fit: fill;
  border: 2px solid #fff;
  border-radius: 60px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.banner-container {
  width: 100%;
  overflow: hidden;
}

.banner-text {
  white-space: nowrap;
  display: inline-block;
  font-size: 24px;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

